<template>
  <analytics-dashboard />
</template>

<script>
import AnalyticsDashboard from "@/view/main/dashboards/analytics";

export default {
  components: {
    AnalyticsDashboard,
  },
};
</script>
